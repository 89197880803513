import moment from "moment";
moment.locale("fr");

export const findSerlianListVisibility = async (listId, callback) => {
  try {
    const response = await fetch(`/api/serliansVisibilityOnLists/${listId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    // console.log("API Response for GET request:", data);

    return data;
  } catch (error) {
    console.error("Error in findSerlianListVisibility:", error);
    throw error;
  }
};

export const changeSerlianVisibilityOnList = (
  serlianId,
  listId,
  cardVisible,
  callback
) => {
  fetch(`/api/serliansVisibilityOnLists/${serlianId}/${listId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      // console.log("API Response for GET request:", response);
      callback && callback(response);

      if (response.rowCount > 0) {
        updateSerlianVisibilityOnList(serlianId, listId, cardVisible);
      } else {
        addSerlianListVisibility(serlianId, listId, cardVisible);
      }
      return response;
    });
};

export const addSerlianListVisibility = (
  serlianId,
  listId,
  cardVisible,
  callback
) => {
  fetch(`/api/serliansVisibilityOnLists`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      serlianId: serlianId,
      listId: listId,
      cardVisible: cardVisible,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("API Response for post request:", response);
      callback && callback(response);
    });
};

export const updateSerlianVisibilityOnList = (
  serlianId,
  listId,
  cardVisible,
  callback
) => {
  fetch(`/api/serliansVisibilityOnLists`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      serlianId: serlianId,
      listId: listId,
      cardVisible: cardVisible,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("API Response for put request:", response);
      callback && callback(response);
    });
};
