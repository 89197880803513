import moment from "moment";
moment.locale("fr");

export const getConferences = (idSerlian, callback) => {
  fetch(`/api/conferences/${idSerlian}`, {
    method: "GET",
    credentials: "include", //on inclut les cookies
    headers: { Accept: "application/json" },
  })
    .then((response) => response.json())
    .then((conferences) => {
      callback && callback(conferences);
    });
};

export const addConference = (conference, idSerlian, callback) => {
  conference.year = moment(conference.year);
  fetch(`/api/conferences/add`, {
    method: "POST",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conference: conference,
      id_serlian: idSerlian,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const deleteConference = (conference, callback) => {
  fetch(`/api/conferences/delete/`, {
    method: "DELETE",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ conference }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const updateConference = (conference, callback) => {
  conference.year = moment(conference.year);
  fetch(`/api/conferences/update`, {
    method: "PUT",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conference: conference,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};
