import { PrimaryButton } from "../../../components/Buttons";
import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../components/Toast";
import { TiDelete } from "react-icons/ti";
import SerlianContext from "../../../context";
import { updateSerlian } from "../../../utils/fetch/fetchUserAdmin";

const EditSerlian = (props) => {
  const context = useContext(SerlianContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    toast: "",
    errorPicture: false,
    errorMailFormat: false,
    errorMailExist: false,
    valueButton: "Mettre à jour",
    disabledButton: false,
    inputFileKey: Date.now(),
    serlian: {
      ...location.state.serlian,
      haspicture: location.state.serlian.haspicture,
    },
    picture: null,
  });
  const [linkErrors, setLinkErrors] = useState({
    blog_article: "",
    linkedin: "",
    github: "",
    twitter: "",
  });

  const {
    serlian,
    errorMailFormat,
    errorMailExist,
    errorPicture,
    inputFileKey,
    valueButton,
  } = state;

  useEffect(() => {
    return () => {
      clearTimeout(state.toastTimer);
    };
  }, [state]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const { serlian } = state;

    if (files) {
      const file = files[0];
      const img = new Image();

      img.onload = function () {
        if (
          this.width !== this.height ||
          this.width > 1000 ||
          this.height > 1000
        ) {
          setState((prevState) => ({
            ...prevState,
            toast: "ERROR_PICTURE_DIM",
            errorPicture: true,
            picture: file,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            errorPicture: false,
            picture: file,
          }));
        }
      };

      img.onerror = function () {
        alert("fichier invalide: " + file.type);
      };
      img.src = window.URL.createObjectURL(file);
      setState((prevState) => ({
        ...prevState,
        serlian: { ...serlian, haspicture: true },
      }));
    } else {
      if (value.length < context.maxLength) {
        serlian[name] = value;
      }
      if (name === "email" && (value.endsWith("@serli.com") || value === "")) {
        setState((prevState) => ({
          ...prevState,
          errorMailFormat: false,
          errorMailExist: false,
        }));
      } else if (name === "email" && !value.endsWith("@serli.com")) {
        setState((prevState) => ({
          ...prevState,
          errorMailFormat: true,
          errorMailExist: false,
        }));
      }
    }

    clearTimeout(window.validateTimeout);
    window.validateTimeout = setTimeout(() => {
      if (value.length > 0 && !validateLink(name, value)) {
        setLinkErrors((prev) => ({
          ...prev,
          [name]: `Le lien pour ${name} est invalide.`,
        }));
      } else {
        setLinkErrors((prev) => ({ ...prev, [name]: "" }));
      }
    }, 500);

    setState((prevState) => ({
      ...prevState,
      serlian: { ...serlian },
    }));
  };

  const updateSerlianInfo = () => {
    const { serlian, picture } = state;
    const formData = new FormData();

    formData.append("picture", picture);
    formData.append("serlian", JSON.stringify(serlian));

    updateSerlian(formData, (response) => {
      if (response.name === "success") {
        setState((prevState) => ({
          ...prevState,
          toast: "SUCCESS",
          errorMailExist: false,
          inputFileKey: Date.now(),
        }));

        if (response.picture && response.picture !== "success") {
          setState({ toast: "ERROR_PICTURE" });
        }

        setTimeout(() => {
          navigate("/serliens/gestion");
        }, 1800);
      } else if (response.message === "EMAIL_EXIST") {
        setState({ errorMailExist: true });
      } else {
        setState({ toast: "ERROR" });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasLinkErrors = Object.values(linkErrors).some(
      (error) => error !== ""
    );
    if (hasLinkErrors) {
      setState((prevState) => ({
        ...prevState,
        toast: "ERROR_LINK",
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      valueButton: <div className="loader" />,
      disabledButton: true,
    }));

    updateSerlianInfo();

    setTimeout(
      function () {
        setState({ toast: "" });
      }.bind(this),
      1000
    );
  };

  //vérification des liens
  const validateLink = (linkname, value) => {
    switch (linkname) {
      case "blog_article":
        return value.startsWith("https://teamserli.blogspot.com/");
      case "linkedin":
        return value.startsWith("https://www.linkedin.com/");
      case "github":
        return value.startsWith("https://github.com/");
      case "twitter":
        return (
          value.startsWith("https://x.com/") ||
          value.startsWith("https://twitter.com/")
        );
      default:
        return true;
    }
  };

  const resetField = () => {
    setState({
      ...state,
      serlian: { ...state.serlian },
      errorPicture: false,
      inputFileKey: Date.now(),
      picture: null,
      toast: "",
    });
  };

  const renderToast = () => {
    switch (state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Le Serlien a bien été enregistré"
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Le Serlien n'a pas pu être enregistré"
          />
        );
      case "ERROR_PICTURE":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="L'image n' âs pu être enregistrée"
          />
        );
      case "ERROR_PICTURE_DIM":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="L'image doit être carré et avoir une taille maximale de 1000 pixels x 1000 pixels"
          />
        );
      case "ERROR_LINK":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Un ou plusieurs liens sont invalides"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container container--column">
      {renderToast()}
      <div className="container container--column container--centered">
        <h2>Editer les informations</h2>
        <em>
          Les champs suivis d'une étoile <u>doivent</u> être remplis
        </em>
        <em>
          Attention, en changeant le nom ou prénom du serlien la photo actuelle
          sera perdue et une nouvelle photo devra être chargée
        </em>
      </div>
      {serlian && (
        <form className="form" onSubmit={handleSubmit}>
          <label className="form__control">
            <span>Prénom *</span>
            <input
              type="text"
              className="form__input"
              name="firstname"
              onChange={handleChange}
              value={serlian.firstname}
              required
            />
          </label>
          <label className="form__control">
            <span>Nom *</span>
            <input
              type="text"
              className="form__input"
              name="lastname"
              onChange={handleChange}
              value={serlian.lastname}
              required
            />
          </label>
          <label className="form__control">
            <span>
              Email *
              {errorMailFormat && (
                <em>Cette adresse mail n'est pas de type serli.com</em>
              )}
              {errorMailExist && <em>Cette adresse mail est déjà utilisée</em>}
            </span>
            <input
              type="email"
              className={
                errorMailFormat || errorMailExist
                  ? "form__input form__error"
                  : "form__input"
              }
              name="email"
              onChange={handleChange}
              value={serlian.email}
              required
            />
          </label>
          <label className="form__control">
            <span>Poste *</span>
            <input
              type="text"
              className="form__input"
              name="job"
              onChange={handleChange}
              value={serlian.job}
              required
            />
          </label>
          <label className="form__control">
            <span>Agence</span>
            <select
              className="form__input"
              name="city"
              onChange={handleChange}
              value={serlian.city}
            >
              <option value="">Selectionner une option</option>
              <option value="Agence de Niort">Agence de Niort</option>
              <option value="Agence de Poitiers">Agence de Poitiers</option>
            </select>
          </label>
          <label className="form__control">
            <span>
              {serlian.picture && (
                <>
                  <p>Photo actuelle</p>
                  <div>
                    <img src={serlian.picture} className="form__picture" />
                  </div>
                </>
              )}
            </span>
            <span>
              Choisir une photo
              {errorPicture && (
                <em>
                  L'image doit être carrée et avoir une taille maximale de 1000
                  pixels x 1000 pixels
                </em>
              )}
            </span>
            <div className="form__input-container form__picture">
              <input
                type="file"
                onChange={handleChange}
                key={inputFileKey}
                className={
                  errorPicture ? "form__input form__error" : "form__input"
                }
                accept="image/jpeg, image/png"
              />
              <button
                onClick={resetField}
                type="button"
                className={
                  state.picture
                    ? "cross-button--picture show"
                    : "cross-button--picture"
                }
              >
                <TiDelete />
              </button>
            </div>
          </label>
          <label className="form__control">
            <span>Lien présentation sur le blog</span>
            <input
              type="text"
              className="form__input"
              name="blog_article"
              onChange={handleChange}
              value={serlian.blog_article}
            />
            {linkErrors.blog_article && (
              <em className="error-message">{linkErrors.blog_article}</em>
            )}
          </label>
          <label className="form__control">
            <span>Lien linkedin</span>
            <input
              type="text"
              className="form__input"
              name="linkedin"
              onChange={handleChange}
              value={serlian.linkedin}
            />
            {linkErrors.linkedin && (
              <em className="error-message">{linkErrors.linkedin}</em>
            )}
          </label>
          <label className="form__control">
            <span>Lien github</span>
            <input
              type="text"
              className="form__input"
              name="github"
              onChange={handleChange}
              value={serlian.github}
            />
            {linkErrors.github && (
              <em className="error-message">{linkErrors.github}</em>
            )}
          </label>
          <label className="form__control">
            <span>Lien twitter</span>
            <input
              type="text"
              className="form__input"
              name="twitter"
              onChange={handleChange}
              value={serlian.twitter}
            />
            {linkErrors.twitter && (
              <em className="error-message">{linkErrors.twitter}</em>
            )}
          </label>
          <PrimaryButton
            disabled={errorMailFormat || errorPicture}
            id="btn-confirm"
            value={valueButton}
          />
        </form>
      )}
    </div>
  );
};

export default EditSerlian;
