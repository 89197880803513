import moment from "moment";
moment.locale("fr");

const defaultBaseline =
  "C'est plus qu'une simple galerie de visages, c'est un voyage au cœur de notre équipe pleine de personnalités uniques. Découvrez les sourires derrière nos projets, où le sérieux se marie à la convivialité. Bienvenue chez Serli, où la créativité et la bonne humeur sont les ingrédients secrets de notre dynamique professionnelle!";

export const getBaseline = (callback) => {
  fetch(`/api/home_baseline`, {
    method: "GET",
    credentials: "include",
    headers: { Accept: "application/json" },
  })
    .then((response) => response.json())
    .then((baseline) => {
      callback && callback(baseline ? baseline.title : defaultBaseline);
    })
    .catch((error) => {
      console.error("Failed to fetch baseline:", error);
      callback && callback(defaultBaseline);
    });
};

export const addBaseline = async (title, callback) => {
  fetch(`/api/home_baseline`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: title,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const deleteBaseline = async (baseline, callback) => {
  fetch(`/api/home_baseline`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ baseline }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("API Response DELETE request:", response);
      callback && callback(response);
    });
};
