import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../../../components/Buttons";
import Toast from "../../../components/Toast";
import { updateList } from "../../../utils/fetch/fetchLists";
import { useNavigate, useParams } from "react-router-dom";
import { getListById } from "../../../utils/fetch/fetchLists";

const EditList = () => {
  const navigate = useNavigate();
  const { listId } = useParams();

  const [state, setState] = useState({
    title: "",
    description: "",
    toast: "",
    valueButton: "Modifier",
    disabledButton: false,
  });

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const fetchedList = await getListById(listId);
        if (fetchedList) {
          setState((prevState) => ({
            ...prevState,
            title: fetchedList.title || "",
            description: fetchedList.description || "",
          }));
        }
      } catch (error) {
        console.error("Error fetching list data:", error);
      }
    };
    fetchListData();
  }, [listId]);

  useEffect(() => {
    return () => {
      clearTimeout(state.toastTimer);
    };
  }, [state]);

  const handleChange = (e) => {
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      valueButton: <div className="loader" />,
      disabledButton: true,
    });
    const form = e.currentTarget;

    updateList(state.title, state.description, listId, (response) => {
      let updatedState = {
        valueButton: "",
        disabledButton: false,
      };

      if (response.name === "success") {
        updatedState.toast = "SUCCESS";
        updatedState.toastTimer = setTimeout(() => {
          setState((prevToastState) => ({ ...prevToastState, toast: "" }));
        }, 2000);

        setTimeout(() => navigate("/lists/display"), 2000);
      } else {
        updatedState.toast = "ERROR";
      }

      setState({
        ...state,
        ...updatedState,
      });
    });
  };

  const renderToast = () => {
    switch (state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="La liste a bien été modifiée."
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La liste n'a pas pu être modifiée."
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container container--centered container--column">
      {renderToast()}
      <h1>Modifier ma liste</h1>

      <form onSubmit={handleSubmit} id="form-list" className="form">
        <label className="form__control">
          <span>Nom de la liste</span>
          <input
            type="text"
            name="title"
            value={state.title}
            className="form__input"
            onChange={handleChange}
            required
          />
        </label>

        <label className="form__control">
          <span>Description</span>
          <textarea
            name="description"
            value={state.description}
            className="form__input"
            onChange={handleChange}
          />
        </label>
        <PrimaryButton
          disabled={state.disabledButton}
          id="btn-confirm"
          value={state.valueButton}
        />
      </form>
    </div>
  );
};

export default EditList;
