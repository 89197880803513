import React, { useState, useEffect } from "react";
import Toast from "../../../components/Toast";
import { FiEdit3 } from "react-icons/fi";
import { TiTrash } from "react-icons/ti";
import { getLists, deleteList } from "../../../utils/fetch/fetchLists";
import { Link } from "react-router-dom";

const ListsManagement = (props) => {
  const [allLists, setAllLists] = useState([]);
  const [toast, setToast] = useState("");

  useEffect(() => {
    getLists((fetchedLists) => {
      setAllLists(fetchedLists);
    });
  }, []);

  const deleteListItem = (list) => {
    const confirmDeleteList = window.confirm(
      `Voulez-vous vraiment supprimer la liste "${list.title}" ?`
    );

    if (!confirmDeleteList) {
      return;
    }

    deleteList(list, (response) => {
      if (response.name === "success") {
        setToast("SUCCESS");
        getLists((fetchedLists) => {
          setAllLists(fetchedLists);
        });
      } else {
        setToast("ERROR");
      }
    });
  };

  const renderToast = () => {
    switch (toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="La liste a bien été modifiée/supprimée."
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La liste n'a pas pu être modifiée/supprimée."
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container container--column">
      {renderToast()}
      <div className="container container--column container--centered">
        <h2> Gestion des listes</h2>
        <Link to={{ pathname: `/lists/create` }}>
          <div className="btn btn--blue">+ Créer une liste</div>
        </Link>
      </div>
      {allLists.map((list) => {
        return (
          <div className="list__line" key={list.id}>
            <Link
              to={{
                pathname: `/lists/${list.id}`,
                state: { list },
              }}
              className="list__item"
              key={list.id}
            >
              <h3>{list.title}</h3>
              <p>{list.description}</p>
            </Link>
            <Link to={`/lists/update/${list.id}`} className="btn btn--round">
              <FiEdit3 />
            </Link>
            <button
              className="btn btn--round"
              onClick={() => deleteListItem(list)}
            >
              <TiTrash />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ListsManagement;
