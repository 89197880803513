import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import SerlianContext from "../../../context";
import { SERLIAN_DEFAULT_PICTURE } from "../../../utils/Images";
import SerlianListCardItem from "./SerlianListCardItem";
import { findSerlianListVisibility } from "../../../utils/fetch/fetchSerliansVisibilityOnLists";
import { getListById } from "../../../utils/fetch/fetchLists";

const SerliansList = () => {
  const { listId } = useParams();
  const context = useContext(SerlianContext);

  const [serlians, setSerlians] = useState([]);
  const [init, setInit] = useState(-1);
  const [insertItem, setInsertItem] = useState(0);
  const [picture, setPicture] = useState(SERLIAN_DEFAULT_PICTURE);
  const [listVisibility, setListVisibility] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState({});

  useEffect(() => {
    const fetchListData = async () => {
      setIsLoading(true);
      try {
        const fetchedList = await getListById(listId);
        setList(fetchedList);
      } catch (error) {
        console.error("Error fetching list:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListData();
  }, [listId]);

  useEffect(() => {
    const fetchVisibilityData = async () => {
      setIsLoading(true);
      try {
        const visibilityData = await findSerlianListVisibility(listId);
        setListVisibility(visibilityData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVisibilityData();
  }, [list]);

  const initSerlians = async () => {
    if (serlians.length === 0 && init < 0) {
      setSerlians(context.serlians);
    }
  };

  useEffect(() => {
    initSerlians();
  }, [context.serlians]);

  // placeholder
  const renderMissItem = () => {
    let table = [];
    for (let i = 1; i <= insertItem; i++) {
      table.push(<i aria-hidden="true" className="hidden-i" key={i} />);
    }
    return table;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return context.serlians.length === 0 ? (
    <div className="container container__page-error">
      <h2>Oups...</h2>
      <p>Il semble qu'il n'y ai aucun Serlien de renseigné.</p>
    </div>
  ) : (
    <>
      <div className="container">
        <div className="list-header">
          <h1>{list.title}</h1>
          <p>{list.description}</p>
        </div>
        <div className="container">
          {context.serliansToShow.length ? (
            context.serliansToShow
              .filter((serlian) => !serlian.archive)
              .sort((a, b) => a.firstname.localeCompare(b.firstname))
              .map((serlian) => {
                const serlianVisibility = listVisibility.find(
                  (data) => data.serlian_id === serlian.id
                );
                let initialCardVisibility = true;

                if (serlianVisibility) {
                  initialCardVisibility = serlianVisibility.card_visible;
                }

                return (
                  <SerlianListCardItem
                    key={serlian.id}
                    serlian={serlian}
                    listId={list.id}
                    initialCardVisibility={initialCardVisibility}
                  />
                );
              })
          ) : (
            <span>Aucun serlien trouvé.</span>
          )}
          {renderMissItem()}
        </div>
      </div>
    </>
  );
};

export default SerliansList;
