import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import Toast from "../../../components/Toast";
import SerlianContext from "../../../context";
import { getSerlians } from "../../../utils/fetch/fetchUser";
import {
  deleteSerlian,
  archiveSerlian,
} from "../../../utils/fetch/fetchUserAdmin";
import { IoMdTrash } from "react-icons/io";
import { FaArchive } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { MdUnarchive } from "react-icons/md";

const SerlianManagement = () => {
  const [sort, setSort] = useState(1);
  const [serlians, setSerlians] = useState([]);
  const [archivedSerlians, setArchivedSerlians] = useState([]);
  const [filter, setFilter] = useState("active");
  const [toast, setToast] = useState("");
  const context = useContext(SerlianContext);

  useEffect(() => {
    updateSerlians();
  }, []);

  const sortSerlian = (serlians) =>
    serlians.sort((a, b) => sort * a.firstname.localeCompare(b.firstname));

  const getActiveSerlians = (serlians) =>
    serlians.filter((elt) => !elt.archive);

  const getArchivedSerlians = (serlians) =>
    serlians.filter((elt) => elt.archive);

  const updateSerlians = () => {
    getSerlians((allSerlians) => {
      if (allSerlians) {
        const activeSerlians = sortSerlian(getActiveSerlians(allSerlians));
        const archivedSortedSerlians = sortSerlian(
          getArchivedSerlians(allSerlians)
        );
        setSerlians(activeSerlians);
        setArchivedSerlians(archivedSortedSerlians);
      } else {
        console.error("Failed to fetch Serlians.");
        setToast("ERRORFETCH");
      }
    });
  };

  const deleteSerlianHandler = (serlian) => {
    deleteSerlian(serlian, (response) => {
      if (response.name === "success") {
        setToast("SUCCESSDEL");
        updateSerlians();
      } else {
        setToast("ERRORDEL");
      }
    });
  };

  const archiveSerlianHandler = (serlian) => {
    archiveSerlian(serlian, (response) => {
      if (response.name === "success") {
        setToast("SUCCESSARCH");
        updateSerlians();
      } else {
        setToast("ERRORARCH");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    if (name === "filter") {
      setFilter(value);
    }
  };

  const handleClick = (serlian, unarchive) => {
    const action =
      filter === "active" ? "archive" : unarchive ? "unarchive" : "delete";
    if (
      window.confirm(
        action === "delete"
          ? `Voulez vous vraiment supprimer le serlien: ${
              serlian.firstname + " " + serlian.lastname
            } ?`
          : action === "archive"
          ? `Voulez vous vraiment archiver le serlien: ${
              serlian.firstname + " " + serlian.lastname
            } ?`
          : `Voulez vous vraiment désarchiver le serlien: ${
              serlian.firstname + " " + serlian.lastname
            } ?`
      )
    ) {
      if (action === "delete") {
        deleteSerlianHandler(serlian);
      } else if (action === "archive" || action === "unarchive") {
        archiveSerlianHandler(serlian);
      }
      setTimeout(() => {
        setToast("");
      }, 8000);
    }
  };

  const renderToast = () => {
    switch (toast) {
      case "SUCCESSARCH":
        return (
          <Toast
            type="success"
            title="Succès"
            message={`Le Serlien a bien été ${
              filter === "active" ? "" : "dés"
            }archivé.`}
          />
        );
      case "SUCCESSDEL":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Le Serlien a bien été supprimé."
          />
        );
      case "ERRORDEL":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Le Serlien n'a pas pu être supprimé, veuillez réessayer plus tard."
          />
        );
      case "ERRORARCH":
        return (
          <Toast
            type="error"
            title="Erreur"
            message={`Le Serlien n'a pas pu être ${
              filter === "active" ? "" : "dés"
            }archivé, veuillez réessayer plus tard.`}
          />
        );
      default:
        return null;
    }
  };

  const allSerlians = filter === "active" ? serlians : archivedSerlians;

  // if (context.serlianConnected.role !== "admin") {
  //   return <Redirect to="/" />;
  // }

  return (
    <div className="container container--centered container--column">
      {renderToast()}
      <div className="container container--column container--centered">
        <h2>Gestion des Serliens</h2>
        <Link to={{ pathname: `/serliens/add` }}>
          <div className="btn btn--blue">+ Ajouter un serlien</div>
        </Link>
      </div>

      <form className="form">
        <>
          <div className="form__control">
            <select
              className="form__input select"
              name="filter"
              value={filter}
              onChange={handleChange}
            >
              <option value="active">
                Serliens actifs ({serlians.length})
              </option>
              <option value="archive">
                Serliens archivés ({archivedSerlians.length})
              </option>
            </select>
          </div>
          <table className="table" cellSpacing="0">
            <thead>
              <tr>
                <th>Prénom</th>
                <th>Nom</th>
                {filter === "active" ? (
                  <th className="table__icon">
                    <FiEdit3 />
                  </th>
                ) : (
                  <th className="table__icon">
                    <MdUnarchive />
                  </th>
                )}
                <th className="table__icon">
                  {filter === "active" ? <FaArchive /> : <IoMdTrash />}
                </th>
              </tr>
            </thead>
            <tbody>
              {allSerlians.map((s) => (
                <tr key={s.id}>
                  <td>{s.firstname}</td>
                  <td>{s.lastname}</td>
                  {filter === "active" ? (
                    <td className="table__icon">
                      <button type="button" className="btn btn--round">
                        <Link
                          to={{
                            pathname: `/serliens/edit`,
                          }}
                          state={{ serlian: s }}
                        >
                          <FiEdit3 />
                        </Link>
                      </button>
                    </td>
                  ) : (
                    <td className="table__icon">
                      <button
                        type="button"
                        className="btn btn--round"
                        onClick={(e) => handleClick(s, true)}
                      >
                        <MdUnarchive />
                      </button>
                    </td>
                  )}
                  <td className="table__icon">
                    <button
                      type="button"
                      className="btn btn--round"
                      onClick={(event) => handleClick(s, false)}
                    >
                      {filter === "active" ? <FaArchive /> : <IoMdTrash />}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </form>
    </div>
  );
};

export default SerlianManagement;
