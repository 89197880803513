import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import Toast from "../components/Toast";
import { sendIssue } from "../utils/fetch/fetchGlobal";

const Contact = ({ serlianConnected }) => {
  const [type, setType] = useState("question");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [toast, setToast] = useState("");
  const [valueButton, setValueButton] = useState("Envoyer");
  const [disabledButton, setDisabledButton] = useState(false);
  const [toastTimer, setToastTimer] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearTimeout(toastTimer);
    };
  }, [toastTimer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "type":
        setType(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValueButton(<div className="loader" />);
    setDisabledButton(true);

    const form = e.currentTarget;
    sendIssue(
      form,
      title,
      type,
      message,
      serlianConnected.email,
      (response) => {
        if (response.message === "success") {
          setToast("SUCCESS");
          form.reset();

          const timer1 = setTimeout(() => {
            setToast("");
          }, 2000);

          setTimeout(() => {
            navigate("/");
          }, 3000);

          setToastTimer(timer1);
        } else {
          setToast("ERROR");
        }

        setValueButton("Envoyer");
        setDisabledButton(false);

        const timer2 = setTimeout(() => {
          setToast("");
        }, 8000);
        setToastTimer(timer2);
      }
    );
  };

  const renderToast = () => {
    switch (toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Votre demande a bien été envoyée, nous la traiterons dans les plus brefs délais."
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Votre demande n'a pas pu être envoyée, veuillez réessayer plus tard."
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container container--column">
      {renderToast()}
      <h1>Contactez-nous</h1>
      <img
        src="/images/contact-serli-mail.png"
        className="img--contact"
        alt="contact"
      />
      <form onSubmit={handleSubmit} id="form-contact" className="form">
        <label className="form__control">
          <span>Je souhaite</span>
          <select
            name="type"
            className="form__input"
            onChange={handleChange}
            value={type}
          >
            <option value="question">Demander une information</option>
            <option value="bug">Signaler un bug</option>
            <option value="idée">Soumettre une idée</option>
          </select>
        </label>

        <label className="form__control">
          <span>Titre</span>
          <input
            type="text"
            name="title"
            className="form__input"
            onChange={handleChange}
            value={title}
            required
          />
        </label>

        <label className="form__control">
          <span>Votre message</span>
          <textarea
            name="message"
            className="form__input"
            onChange={handleChange}
            value={message}
            required
          />
        </label>
        <PrimaryButton
          disabled={disabledButton}
          id="btn-confirm"
          value={valueButton}
        />
      </form>
    </div>
  );
};

export default Contact;
