import React, { Component } from "react";
import SerlianContext from "../../../context";
import moment from "moment";
import "moment/locale/fr";
import Toast from "../../../components/Toast";
import { MdDeleteSweep } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { Redirect } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import { getUserMissions } from "../../../utils/fetch/fetchMissions";
import {
  deleteMission,
  updateOrAddMission,
} from "../../../utils/fetch/fetchMissionsAdmin";
import Autosuggest from "react-autosuggest";

moment().locale("fr");

class MissionsManagement extends Component {
  static contextType = SerlianContext;

  constructor(props) {
    super(props);
    this.state = {
      missions: [],
      toast: "",
      linesToDelete: [],
      selectedMission: 0,
      sort: 1,
      idSerlian: "",
      nbLinesToAdd: 0,
      serlians: [],
      searchEdit: "",
      errorMission: false,
    };

    this.deleteMission = this.deleteMission.bind(this);
  }

  componentDidMount = () => {
    this.setState({ missions: [] });
  };

  getActiveSerlians = (serlians) => {
    serlians = serlians.filter((elt) => !elt.archive);
    return serlians;
  };

  sortSerlian = (serlians) => {
    return serlians.sort(
      (a, b) => this.state.sort * a.firstname.localeCompare(b.firstname)
    );
  };

  getSuggestions = (value) => {
    let inputValue = this.noAccent(value.trim().toLowerCase());
    let inputLength = inputValue.length;

    return Promise.resolve(
      inputLength === 0
        ? []
        : this.context.serlians.filter(
            (serlian) =>
              this.noAccent(
                serlian.firstname.toLowerCase() +
                  " " +
                  serlian.lastname.toLowerCase()
              ).startsWith(inputValue) ||
              this.noAccent(
                serlian.lastname.toLowerCase() +
                  " " +
                  serlian.firstname.toLowerCase()
              ).startsWith(inputValue)
          )
    );
  };

  onSuggestionsClearRequested = () => {
    this.setState({ serlians: [] });
  };

  getSuggestionValue = (serlian) => {
    return serlian.id;
  };

  renderSuggestion = (serlian) => (
    <div>
      {serlian.firstname} {serlian.lastname}
    </div>
  );

  onSuggestionsFetchRequested = (e) => {
    this.getSuggestions(e.value).then((suggestions) =>
      this.setState({ serlians: suggestions })
    );
  };

  onSuggestionSelected = (e, suggestion, index) => {
    this.getMissionsOfUser(suggestion.suggestion.id);
    this.setState({
      searchEdit:
        suggestion.suggestion.firstname + " " + suggestion.suggestion.lastname,
    });
  };

  getMissionsOfUser = (id) => {
    getUserMissions(id, (missions) => {
      this.setState({ missions, idSerlian: id });
    });
  };

  handleChangeSerlian = (e) => {
    let target = e.target;
    if (target.value) {
      this.setState({ searchEdit: target.value });
    } else {
      this.setState({ missions: [], searchEdit: target.value });
    }
  };

  addMissionField = () => {
    let mission;
    let { nbLinesToAdd } = this.state;

    mission = {
      id_user: this.state.idSerlian,
      current: false,
      client_name: "",
      position_held: "",
      begin_time: moment().format("YYYY-MM-DD"),
      end_time: null,
      workplace: "",
    };
    nbLinesToAdd++;

    this.state.missions.push(mission);

    this.setState({ missions: this.state.missions, nbLinesToAdd });
  };

  // Permet de retourner une liste d'année à partir de/jusqu'à une date
  years = (back, year, after) => {
    return Array.from({ length: back }, (v, i) =>
      after ? year.getFullYear() + i : year.getFullYear() - back + i + 1
    );
  };

  noAccent = (string) => {
    let accent = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A, a
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];
    let noAccent = [
      "A",
      "a",
      "E",
      "e",
      "I",
      "i",
      "O",
      "o",
      "U",
      "u",
      "N",
      "n",
      "C",
      "c",
    ];
    accent.map((acc, i) => {
      string = string.replace(acc, noAccent[i]);
    });
    return string;
  };

  handleChange = (event, indexMission) => {
    let source = event.target;
    let name = source.getAttribute("name");
    let indexInput = source.getAttribute("dataindex");
    let missions = this.state.missions;
    let maxLen = this.context.maxLength;
    switch (name) {
      case "serlian":
        missions[indexInput].search = source.value;
        this.setState({ missions });
        break;

      case "current":
        missions[indexMission].current = !missions[indexMission].current;
        missions[indexMission].end_time = null;
        this.setState({ missions });
        break;

      case "client_name":
        if (source.value.length < maxLen)
          missions[indexMission].client_name = source.value;
        this.setState({ missions });
        break;

      case "position_held":
        if (source.value.length < maxLen)
          missions[indexMission].position_held = source.value;
        this.setState({ missions });
        break;

      case "workplace":
        if (source.value.length < maxLen)
          missions[indexMission].workplace = source.value;
        this.setState({ missions });
        break;

      case "begin_time_mobile":
        missions[indexMission].begin_time = moment(source.value).format(
          "YYYY-MM-DD"
        );
        this.setState({ missions });
        break;

      case "end_time_mobile":
        missions[indexMission].end_time = moment(source.value).format(
          "YYYY-MM-DD"
        );
        this.setState({ missions });
        break;

      case "begin_time_month":
        missions[indexMission].begin_time = moment(
          moment(missions[indexMission].begin_time)
            .format("MM/YYYY")
            .substr(3) +
            "-" +
            source.value
        ).format("YYYY-MM-DD");
        this.setState({ missions });
        break;

      case "begin_time_year":
        missions[indexMission].begin_time = moment(
          source.value +
            "-" +
            moment(missions[indexMission].begin_time)
              .format("MM/YYYY")
              .substr(0, 2)
        ).format("YYYY-MM-DD");
        this.setState({ missions });
        break;

      case "end_time_month":
        if (missions[indexMission].end_time !== null) {
          missions[indexMission].end_time = moment(
            moment(missions[indexMission].end_time)
              .format("MM/YYYY")
              .substr(3) +
              "-" +
              source.value
          ).format("YYYY-MM-DD");
        } else {
          missions[indexMission].end_time = moment(
            moment(missions[indexMission].begin_time)
              .format("MM/YYYY")
              .substr(3) +
              "-" +
              source.value
          ).format("YYYY-MM-DD");
        }
        this.setState({ missions });
        break;

      case "end_time_year":
        if (missions[indexMission].end_time !== null) {
          missions[indexMission].end_time = moment(
            source.value +
              "-" +
              moment(missions[indexMission].end_time)
                .format("MM/YYYY")
                .substr(0, 2)
          ).format("YYYY-MM-DD");
        } else {
          missions[indexMission].end_time = moment(
            source.value +
              "-" +
              moment(missions[indexMission].begin_time)
                .format("MM/YYYY")
                .substr(0, 2)
          ).format("YYYY-MM-DD");
        }
        this.setState({ missions });
        break;

      case "end_time":
        missions[indexMission].end_time = moment(source.value).format(
          "YYYY-MM-DD"
        );
        this.setState({ missions });
        break;

      default:
        break;
    }
  };

  // Ajoute les lignes à supprimer dans un tableau
  deleteLines = (e, mission) => {
    let { linesToDelete } = this.state;
    if (e.target.checked) {
      linesToDelete.push(mission);
    } else {
      linesToDelete.splice(linesToDelete.indexOf(mission), 1);
    }
    this.setState({ linesToDelete });
  };

  // Supprime les lignes qui sont dans le tableau des lignes à supprimer
  onClickDeleteLines = (e) => {
    e.preventDefault();
    let { missions, linesToDelete } = this.state;

    linesToDelete.map((line) => {
      missions.splice(missions.indexOf(line), 1);
    });

    this.setState({ missions: missions, linesToDelete: [] });
    if (missions.length === 0) {
      this.addMissionField();
    }
    document.querySelectorAll("input.input-supp").forEach((element) => {
      element.checked = false;
    });
  };

  deleteMission = (id, id_user) => {
    if (confirm("Voulez vous vraiment supprimer cette mission ?")) {
      deleteMission(id, (response) => {
        if (response.name === "success") {
          this.setState({ toast: "SUCCESSDEL" });
        } else {
          this.setState({ toast: "ERRORDEL" });
        }
        let toastTimer = setTimeout(() => {
          this.setState({ toast: "" });
        }, 8000);
        this.setState({ toastTimer });
        this.getMissionsOfUser(id_user);
      });
    }
  };

  updateOrAddMission = (update, missions) => {
    updateOrAddMission(update, missions, (response) => {
      if (response.name === "success") {
        this.setState({ toast: "SUCCESS", nbLinesToAdd: 0 });
      } else {
        this.setState({ toast: "ERROR" });
      }
      let toastTimer = setTimeout(
        function () {
          this.setState({ toast: "" });
        }.bind(this),
        8000
      );
      this.setState({ toastTimer });
    });
  };

  handleDeleteLine = (event, idMission, idUser, index) => {
    event.preventDefault();
    if (idMission !== undefined) {
      this.deleteMission(idMission, idUser);
    } else {
      this.state.missions.splice(index, 1);
      this.setState({ missions: this.state.missions });
    }
  };

  handleSubmitSlice = (event) => {
    let { missions, nbLinesToAdd } = this.state;
    let endIndex = missions.length - nbLinesToAdd;
    let updateMissions = missions.slice(0, endIndex);
    let addMissions = missions.slice(endIndex, missions.length);
    if (updateMissions.length > 0)
      this.handleSubmit(event, true, updateMissions);
    if (addMissions.length > 0) this.handleSubmit(event, false, addMissions);
  };

  handleSubmit = (event, update, missions) => {
    event.preventDefault();
    let errorMission = false;
    missions.length > 0 &&
      missions.map((m, index) => {
        if (m.id_user === "" || m.id_user === undefined) {
          missions.splice(index, 1);
          errorMission = true;
        }
      });
    this.setState({ errorMission }, () => {
      if (missions.length > 0) {
        this.updateOrAddMission(update, missions);
      } else {
        this.setState({ toast: "ERROR" });
        let toastTimer = setTimeout(
          function () {
            this.setState({ toast: "" });
          }.bind(this),
          8000
        );
        this.setState({ toastTimer });
        this.addMissionField();
      }
    });
  };

  resetField = (index) => {
    let { missions } = this.state;
    missions = [];

    this.setState({ searchEdit: "", missions, idSerlian: "" });
  };

  renderInputComponent = (inputProps) => {
    let index = null;
    if (inputProps.dataindex >= 0) {
      index = inputProps.dataindex;
    }
    return (
      <>
        <div className="container">
          <input {...inputProps} />
          <button
            onClick={() => this.resetField(index)}
            type="button"
            className={"cross-button" + (inputProps.value ? " show" : "")}
          >
            <TiDelete />
          </button>
        </div>
      </>
    );
  };

  renderToast = () => {
    switch (this.state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Les missions ont bien été enregistrées"
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Les missions n'ont pas pu être enregistrées"
          />
        );
      case "SUCCESSDEL":
        return (
          <Toast
            type="success"
            title="Succès"
            message={"La missions a bien été supprimée."}
          />
        );
      case "ERRORDEL":
        return (
          <Toast
            type="error"
            title="Erreur"
            message={
              "La mission n'a pas pu être supprimée, veuillez réessayer plus tard."
            }
          />
        );
      default:
        return null;
    }
  };

  render = () => {
    const screen = this.context.isMobile;
    const { selectedMission, serlians, searchEdit, idSerlian } = this.state;
    const inputPropsEdit = {
      placeholder: "Chercher un serlien",
      value: searchEdit,
      name: "serlian",
      className: "form__input--search-serlian",
      onChange: this.handleChangeSerlian,
    };
    let inputPropsAddMobile = {
      placeholder: "Chercher un serlien",
      value:
        this.state.missions.length > 0 ? this.state.missions[0].search : "",
      name: "serlian",
      className: "form__input",
      dataindex: 0,
      onChange: this.handleChange,
    };
    if (this.context.serlianConnected.role !== "admin") {
      return <Redirect to="/" />;
    }

    return (
      <div className="table ">
        {this.renderToast()}
        <div className="container container--column container--centered">
          <h2 className="test">Gestion des missions</h2>
          <em>
            Les champs suivis d'une étoile <u>doivent</u> être remplis
          </em>
          <span>
            Sélectionnez un serlien pour modifier ou ajouter des missions
          </span>
        </div>

        <div className="container container--column">
          <label className="form__control">
            Serlien
            <Autosuggest
              suggestions={serlians}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={(e, suggestion) => {
                this.onSuggestionSelected(e, suggestion);
              }}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputPropsEdit}
              renderInputComponent={this.renderInputComponent}
            />
          </label>
          {this.state.missions.length > 0 && screen && (
            <label className="form__control">
              Mission
              <select
                className="form__input"
                onChange={(e) =>
                  this.setState({ selectedMission: e.target.value })
                }
              >
                {this.state.missions.map((mission, index) => {
                  return (
                    <option value={index} key={index}>
                      {" "}
                      {mission.client_name} -{" "}
                      {moment(mission.begin_time).format("MM/YYYY")}
                    </option>
                  );
                })}
              </select>
            </label>
          )}
        </div>

        <form
          className="form"
          action=""
          onSubmit={(e) => {
            this.handleSubmitSlice(e);
          }}
        >
          {idSerlian && (
            <button
              className="btn btn--border-blue"
              type="button"
              onClick={this.addMissionField}
            >
              + Ajouter une mission
            </button>
          )}
          {screen ? (
            <div className="form--mobile">
              {/* ---------------------------------- MOBILE ---------------------------------- */}
              {this.state.missions.length > 0 && (
                <>
                  <label className="form__control">
                    <span>Mission en cours ? </span>
                    <input
                      name="current"
                      key="current"
                      type="checkbox"
                      onChange={(e) => this.handleChange(e, selectedMission)}
                      checked={this.state.missions[selectedMission].current}
                    />
                  </label>
                  <label className="form__control">
                    <span>Client * </span>
                    <input
                      name="client_name"
                      key="client_name"
                      onChange={(e) => this.handleChange(e, selectedMission)}
                      placeholder="Nom du client"
                      className="form__input"
                      value={this.state.missions[selectedMission].client_name}
                    />
                  </label>
                  <label className="form__control">
                    <span>Poste * </span>
                    <input
                      name="position_held"
                      key="position_held"
                      onChange={(e) => this.handleChange(e, selectedMission)}
                      placeholder="Poste occupé"
                      className="form__input"
                      value={this.state.missions[selectedMission].position_held}
                    />
                  </label>
                  <label className="form__control">
                    <span>Lieu de travail</span>
                    <input
                      className="form__input"
                      name="workplace"
                      onChange={(e) => this.handleChange(e, selectedMission)}
                      placeholder="Lieu de travail"
                      value={
                        this.state.missions[selectedMission].workplace
                          ? this.state.missions[selectedMission].workplace
                          : ""
                      }
                    />
                  </label>
                  <label className="form__control">
                    <span>Date de début * </span>
                    <input
                      name="begin_time_mobile"
                      key="begin_time"
                      type="month"
                      value={moment(
                        this.state.missions[selectedMission].begin_time
                      ).format("YYYY-MM")}
                      onChange={(e) => this.handleChange(e, selectedMission)}
                      className="form__input"
                    />
                  </label>
                  <label className="form__control">
                    <span>Date de fin </span>
                    <input
                      name="end_time_mobile"
                      type="month"
                      onChange={(e) => this.handleChange(e, selectedMission)}
                      min={moment(
                        this.state.missions[selectedMission].begin_time
                      ).format("YYYY-MM")}
                      value={moment(
                        this.state.missions[selectedMission].end_time
                      ).format("YYYY-MM")}
                      disabled={this.state.missions[selectedMission].current}
                      className="form__input"
                    />
                  </label>
                </>
              )}
              {this.state.missions.length > 0 && (
                <div className="container container--column container--centered">
                  <button
                    type="button"
                    className="btn btn-round"
                    onClick={() =>
                      this.deleteMission(
                        this.state.missions[selectedMission].id,
                        this.state.missions[selectedMission].id_user
                      )
                    }
                  >
                    <IoMdTrash />
                  </button>

                  <button className="btn btn--blue mobile-button" type="submit">
                    Mettre à jour
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              {/* ---------------------------------- DESKTOP ---------------------------------- */}
              <div className="container">
                <table className="table table--missions" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>En cours?</th>
                      <th>Nom du client *</th>
                      <th>Poste occupé *</th>
                      <th>Lieu de travail</th>
                      <th>Date de début *</th>
                      <th>Date de fin</th>
                      <th>
                        {this.state.linesToDelete.length > 0 && (
                          <button
                            className="btn btn--round btn--blue"
                            onClick={this.onClickDeleteLines}
                          >
                            Suppr({this.state.linesToDelete.length})
                          </button>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.missions &&
                      this.state.missions.map((m, index) => {
                        const inputPropsM = {
                          placeholder: "Chercher un serlien",
                          value: m.search,
                          name: "serlian",
                          className: "form__input",
                          dataindex: index,
                          onChange: this.handleChange,
                        };
                        return (
                          <tr
                            style={{ position: "relative" }}
                            key={index + "mission"}
                          >
                            <td className="table__icon">
                              <input
                                name="current"
                                type="checkbox"
                                onChange={(e) => this.handleChange(e, index)}
                                checked={m.current}
                              />
                            </td>
                            <td className="table__cell">
                              <input
                                className="form__input"
                                name="client_name"
                                onChange={(e) => this.handleChange(e, index)}
                                placeholder="Nom du client"
                                value={m.client_name}
                                required
                              />
                            </td>
                            <td className="table__cell">
                              <input
                                className="form__input"
                                name="position_held"
                                onChange={(e) => this.handleChange(e, index)}
                                placeholder="Poste ocupé"
                                value={m.position_held}
                              />
                            </td>
                            <td className="table__cell">
                              <input
                                className="form__input"
                                name="workplace"
                                onChange={(e) => this.handleChange(e, index)}
                                placeholder="Lieu de travail"
                                value={m.workplace ? m.workplace : ""}
                              />
                            </td>
                            <td className="table__cell">
                              <select
                                className="form__input form__input--select-month"
                                name="begin_time_month"
                                onChange={(e) => this.handleChange(e, index)}
                                value={moment(m.begin_time)
                                  .format("MM/YYYY")
                                  .substr(0, 2)}
                                required
                              >
                                {moment.months().map((month, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={
                                        index + 1 > 9
                                          ? index + 1
                                          : "0" + (index + 1)
                                      }
                                    >
                                      {month}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                className="form__input form__input--select-year"
                                name="begin_time_year"
                                onChange={(e) => this.handleChange(e, index)}
                                value={moment(m.begin_time)
                                  .format("MM/YYYY")
                                  .substr(3)}
                              >
                                {this.years(30, new Date(), false).map(
                                  (year, index) => {
                                    return (
                                      <option key={index} value={year}>
                                        {year}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </td>
                            <td className="table__cell">
                              <select
                                className="form__input form__input--select-month"
                                name="end_time_month"
                                onChange={(e) => this.handleChange(e, index)}
                                value={
                                  m.end_time
                                    ? moment(m.end_time)
                                        .format("MM/YYYY")
                                        .substr(0, 2)
                                    : ""
                                }
                                disabled={m.current}
                              >
                                <option value="">----</option>
                                {moment.months().map((month, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={
                                        index + 1 > 9
                                          ? index + 1
                                          : "0" + (index + 1)
                                      }
                                    >
                                      {month}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                className="form__input form__input--select-year"
                                name="end_time_year"
                                onChange={(e) => this.handleChange(e, index)}
                                value={
                                  m.end_time
                                    ? moment(m.end_time)
                                        .format("MM/YYYY")
                                        .substr(3)
                                    : ""
                                }
                                disabled={m.current}
                              >
                                <option value="">----</option>
                                {this.years(
                                  30,
                                  new Date(m.begin_time),
                                  true
                                ).map((year, index) => {
                                  return (
                                    <option key={index} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td className="table__icon">
                              <button
                                type="button"
                                className="btn btn--blue"
                                onClick={(event) =>
                                  this.handleDeleteLine(
                                    event,
                                    m.id,
                                    m.id_user,
                                    index
                                  )
                                }
                              >
                                <IoMdTrash />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div className="container container--column container--centered">
                {this.state.missions.length > 0 && (
                  <button className="btn btn--blue" type="submit">
                    Mettre à jour & Envoyer
                  </button>
                )}
              </div>
            </>
          )}
        </form>
      </div>
    );
  };
}

export default MissionsManagement;
