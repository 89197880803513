import React, { useState, useEffect, useContext } from "react";
import BurgerMenu from "./BurgerMenu";
import SerlianContext from "../context";
import SearchSerlian from "./SearchSerlian";
import { Link, useNavigate } from "react-router-dom";

const Header = ({ user, setSerlian, setSerliansToShow }) => {
  const navigate = useNavigate();
  const context = useContext(SerlianContext);

  const [serlians, setSerlians] = useState([]);
  const [init, setInit] = useState(false);
  const [insertItem, setInsertItem] = useState(0);
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    if (!init) {
      initSerlians();
    }
  }, [context.serlians, init]);

  useEffect(() => {
    if (serlians && serlians.length > 0) {
      let nbItemPerLine = window.innerWidth / 171;
      let nbLine = serlians.length / Math.floor(nbItemPerLine);
      let nbNewItem =
        Math.floor(nbItemPerLine) -
        (serlians.length - Math.floor(nbItemPerLine) * Math.floor(nbLine));

      if (nbNewItem !== insertItem && nbNewItem !== Math.floor(nbItemPerLine)) {
        setInsertItem(nbNewItem);
      }
    }
  }, [serlians, insertItem, init]);

  const initSerlians = () => {
    setSerlians(context.serlians);
    setInit(!init);
  };

  const logoClick = () => {
    setSearchField("");
    setSerlians(context.serlians);
    setSerliansToShow(context.serlians);
    setInit(true);
    navigate("/");
  };

  return (
    <header className="banner">
      <nav className="navbar">
        <div className="navbar__brand">
          <Link to="/" onClick={logoClick}>
            TROMBINOSCOPE
          </Link>
        </div>
        <div className="navbar__user">
          <SearchSerlian
            setSerlians={setSerlian}
            searchField={searchField}
            setSearchField={setSearchField}
          />
        </div>
      </nav>
      <BurgerMenu user={user} />
    </header>
  );
};

export default Header;
