import React from "react";
import { FaCheck } from "react-icons/fa";
import { MdError } from "react-icons/md";

const Toast = ({ type, title, message }) => {
  const renderIcon = () => {
    switch (type) {
      case "success":
        return <FaCheck />;
      case "error":
        return <MdError />;
      default:
        return null;
    }
  };

  return (
    <div className={`toast ${type}`} id="toast">
      <span>
        {renderIcon()} {title}
      </span>
      <p>{message}</p>
    </div>
  );
};

export default Toast;
