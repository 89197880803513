import React, { useState, useEffect } from "react";
import { slide as BurgerMenuSlide } from "react-burger-menu";
import { Link, useNavigate } from "react-router-dom";
import { LogOutButton, UserButton } from "./Buttons";

const BurgerMenuComponent = ({ user }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const getFirstAndLastName = () => {
    let mail = user.email;
    let split = mail.split("@");
    return split[0];
  };

  const displayMenu = (e) => {
    setOpenMenu(true);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const handleProfileClick = (e, path) => {
    e.preventDefault();
    closeMenu();
    navigate(path);
  };

  return (
    <div className="menu">
      {user && (
        <>
          <div className="menu__user">
            <p className="menu__user__name">
              {user.name
                ? user.name.split(" ")[0]
                : user.email.split("@")[0].split(".")[0]}
            </p>

            <UserButton user={user} displayMenu={displayMenu} />
            <BurgerMenuSlide
              right
              isOpen={openMenu}
              onOpen={displayMenu}
              onClose={closeMenu}
            >
              <div id="menu-container">
                <div className="menu__user__name">
                  <h2>
                    Salut,{" "}
                    {user.name
                      ? user.name.split(" ")[0]
                      : user.email.split("@")[0].split(".")[0]}
                  </h2>
                </div>
                <div>
                  {user.id ? (
                    <Link
                      to={{
                        pathname: `/serliens/${getFirstAndLastName()}`,
                      }}
                      onClick={(e) =>
                        handleProfileClick(
                          e,
                          `/serliens/${getFirstAndLastName()}`
                        )
                      }
                    >
                      <p>Mon profil</p>
                    </Link>
                  ) : (
                    <Link
                      to={{ pathname: "/serliens/error" }}
                      onClick={closeMenu}
                    >
                      <p>Mon profil</p>
                    </Link>
                  )}
                  <Link to={{ pathname: "/contact" }} onClick={closeMenu}>
                    <p>Contact</p>
                  </Link>
                  <LogOutButton />
                </div>
                <div>
                  <div>
                    <h2 className="menu__user__section__title ">Serli Apps</h2>
                  </div>
                  <a
                    href="https://trombinoscope.serliapps.dev"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Trombinoscope</p>
                  </a>
                  <a
                    href="https://whereby-rooms-status.serliapps.dev"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Whereby</p>
                  </a>
                  <a
                    href="http://teamserli.blogspot.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Blog</p>
                  </a>
                  <a
                    href="https://serli.vsactivity.com/o_homepage/view_repository/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Documents d'entreprise</p>
                  </a>
                  <a
                    href="https://serli.vsactivity.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>VSA</p>
                  </a>
                  <a
                    href="https://serli.slack.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Slack</p>
                  </a>
                  <a
                    href="https://sharli.serliapps.dev"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Sharli</p>
                  </a>
                  <a
                    href="https://el-curator.serliapps.dev"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>El Curator</p>
                  </a>
                  <a
                    href="https://lunchatwork.serliapps.dev"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Lunch at Work</p>
                  </a>
                  <a
                    href="https://serflix.serliapps.dev"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    <p>Serflix</p>
                  </a>
                </div>
                {user.role === "admin" && (
                  <>
                    <div>
                      <h2 className="menu__user__section__title ">
                        Administrateur
                      </h2>
                    </div>
                    <div>
                      <div>
                        <Link
                          to={{ pathname: `/lists/display` }}
                          onClick={closeMenu}
                        >
                          <p>Gestion listes</p>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={{ pathname: `/missions/gestion` }}
                          onClick={closeMenu}
                        >
                          <p>Gestion missions</p>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={{ pathname: `/serliens/gestion` }}
                          onClick={closeMenu}
                        >
                          <p>Gestion serliens</p>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={{ pathname: `/skills/gestion` }}
                          onClick={closeMenu}
                        >
                          <p>Gestion compétences</p>
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </BurgerMenuSlide>
          </div>
        </>
      )}
    </div>
  );
};

export default BurgerMenuComponent;
