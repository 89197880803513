import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Informations from "../components/serlian/informations/Informations";
import IsPending from "../components/IsPending";
import SerlianContext from "../context";
import Toast from "../components/Toast";
import { getSerlian, updateProfilPicture } from "../utils/fetch/fetchUser";
import { LOADING } from "../utils/Images";

const SerlianProfile = (props) => {
  const { name } = useParams();

  const [isPending, setIsPending] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [serlianNotFound, setSerlianNotFound] = useState(false);
  const [serlian, setSerlian] = useState(null);
  const [updateSerlian, setUpdateSerlian] = useState(false);
  const [toast, setToast] = useState("");
  const [toastTimer, setToastTimer] = useState(null);
  const [isMounted, setIsMounted] = useState(true);

  const context = useContext(SerlianContext);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const getSerlianData = async () => {
      try {
        const result = await getSerlian(name.toLowerCase());
        if (result.message) {
          setSerlianNotFound(true);
          setIsPending(false);
        } else {
          if (isMounted) {
            setSerlian(result);
            setIsPending(false);
            setUpdateSerlian(false);
          }
        }
      } catch (error) {
        console.error("Error fetching Serlian data:", error);
      }
    };

    getSerlianData();

    return () => {
      setIsMounted(false);
    };
  }, [name, serlian, isMounted]);

  useEffect(() => {
    if (serlian && context.serlianConnected.email) {
      if (context.serlianConnected.email === serlian.email) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
    }
  }, [serlian, context.serlianConnected]);

  useEffect(() => {
    return () => {
      if (toastTimer) {
        clearTimeout(toastTimer);
      }
    };
  }, [toastTimer]);

  useEffect(() => {
    if (context.isMobile !== null) {
      window.scrollTo(0, 0);
    }
  }, [context.isMobile]);

  const updateProfilPictureHandler = (e) => {
    const updatedSerlian = { ...serlian };
    updatedSerlian.picture = LOADING;
    setSerlian(updatedSerlian);

    const file = e.target.files[0];
    const img = new Image();

    img.onload = function () {
      if (this.width !== this.height) {
        setToast("ERROR_PICTURE_DIM");
      }
      if (img.width > 1000 || img.height > 1000) {
        setToast("ERROR_PICTURE_DIM");
      } else {
        updatedSerlian.picture = this.src;
        updatedSerlian.haspicture = true;
        updateProfilPicture(file, updatedSerlian, (response) => {
          if (response.name === "success") {
            setSerlian(updatedSerlian);
            setToast("SUCCESS");
            window.location.reload();
          } else {
            setToast("ERROR");
          }
        });
      }
    };

    img.onerror = function () {
      alert("not a valid file: " + file.type);
    };

    img.src = window.URL.createObjectURL(file);

    const timer = setTimeout(() => {
      setToast("");
    }, 8000);

    setToastTimer(timer);
  };

  const renderToast = () => {
    switch (toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="L'image a bien été modifiée."
          />
        );
      case "ERROR_PICTURE_DIM":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="L'image doit être carré et avoir une taille maximale de 1000 pixels x 1000 pixels"
          />
        );
      default:
        return null;
    }
  };

  return isPending ? (
    <IsPending />
  ) : serlianNotFound ? (
    <div className="container container--column container--page-error">
      <h2>Oups...</h2>
      <p>Il semble que le Serlien demandé n'existe pas.</p>
    </div>
  ) : (
    <div className="container">
      {renderToast()}
      <>
        <Informations
          serlian={serlian}
          idSerlian={serlian.id}
          isEditable={isEditable}
          updateProfilPicture={updateProfilPictureHandler}
        />
      </>
    </div>
  );
};

export default SerlianProfile;
