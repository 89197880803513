import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SerlianContext from "./context";
import Home from "./screens/Home";
import SerlianProfile from "./screens/SerlianProfile";
import IsPending from "./components/IsPending";
import Header from "./components/Header";
import Contact from "./screens/Contact";
import ErrorSerlianProfile from "./components/ErrorSerlianProfile";
import { getSerlians } from "./utils/fetch/fetchUser";
import "../client/css/main.scss";

import ListsManagement from "./screens/admin/lists/ListsManagement";
import EditList from "./screens/admin/lists/EditList";
import SerliansList from "./screens/admin/lists/SerliansList";
import GestionSkills from "./screens/admin/skills/SkillsManagement";
import AddList from "./screens/admin/lists/AddList";
import MissionsManagement from "./screens/admin/missions/MissionsManagement";
import SerlianManagement from "./screens/admin/serlian/SerlianManagement";
import AddSerlian from "./screens/admin/serlian/AddSerlian";
import EditSerlian from "./screens/admin/serlian/EditSerlian";

const App = () => {
  const [serlians, setSerlians] = useState([]);
  const [serliansToShow, setSerliansToShow] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [isPending, setIsPending] = useState(true);
  const [errorConn, setErrorConn] = useState(false);
  const [serlianConnected, setSerlianConnected] = useState(null);

  useEffect(() => {
    getAllSerlians();
    getSerlianConnected();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const getAllSerlians = () => {
    getSerlians((serlians) => {
      if (serlians.message && serlians.message === "CONNECTION_FAILED") {
        setErrorConn(true);
        setIsPending(false);
      } else if (serlians.message && serlians.message === "NO_RESULT") {
        setIsPending(false);
      } else {
        const sortedSerlians = serlians
          .filter((serlian) => !serlian.archive)
          .sort((a, b) => {
            const firstNameComparison = a.firstname.localeCompare(b.firstname);
            if (firstNameComparison !== 0) {
              return firstNameComparison;
            }
            return a.lastname.localeCompare(b.lastname);
          });

        setSerlians(sortedSerlians);
        setSerliansToShow(sortedSerlians);
        setIsPending(false);
      }
    });
  };

  const getSerlianConnected = () => {
    fetch("/api/global/me", {
      method: "GET",
      credentials: "include", // on inclut les cookies
      headers: { Accept: "application/json" },
    })
      .then((r) => r.json())
      .then((serlianConnected) => {
        setSerlianConnected(serlianConnected);
      });
  };

  // Contexte
  const context = {
    serlians,
    serliansToShow,
    setSerliansToShow,
    serlianConnected,
    isMobile:
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i),
    isTablet:
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/Tablet/i),
    maxLength: 10485760,
  };

  return (
    <SerlianContext.Provider value={context}>
      <Router>
        {isPending ? (
          <div>
            <Header
              user={context.serlianConnected}
              setSerlian={setSerlians}
              setSerliansToShow={setSerliansToShow}
            />
            <IsPending />
          </div>
        ) : errorConn ? (
          <div>
            <Header
              user={context.serlianConnected}
              setSerlian={setSerlians}
              setSerliansToShow={setSerliansToShow}
            />
            <div className="container container--column container--page-error">
              <h2>Oups...</h2>
              <p>Une erreur de connexion est survenue...</p>
            </div>
          </div>
        ) : (
          <div>
            <Header
              user={context.serlianConnected}
              setSerlian={setSerlians}
              setSerliansToShow={setSerliansToShow}
            />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    setSerlians={setSerlians}
                    user={context.serlianConnected}
                  />
                }
              />
              <Route path="/serliens/error" element={<ErrorSerlianProfile />} />
              <Route
                path="/serliens/:name"
                element={<SerlianProfile user={context.serlianConnected} />}
              />
              <Route
                path="/contact"
                element={<Contact serlianConnected={serlianConnected} />}
              />
              <Route path="/serliens/gestion" element={<SerlianManagement />} />
              <Route path="/serliens/add" element={<AddSerlian />} />
              <Route path="/serliens/edit" element={<EditSerlian />} />
              <Route path="/lists/create" element={<AddList />} />
              <Route path="/lists/display" element={<ListsManagement />} />
              <Route path="/lists/update/:listId" element={<EditList />} />
              <Route path="/lists/:listId" element={<SerliansList />} />
              <Route
                path="/missions/gestion"
                element={<MissionsManagement />}
              />
              <Route path="/skills/gestion" element={<GestionSkills />} />
            </Routes>
          </div>
        )}
      </Router>
    </SerlianContext.Provider>
  );
};

export default App;

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
