import React from "react";

export const UserButton = ({ user, displayMenu }) => (
  <button className="btn--transparent">
    {user && (
      <img
        src={user.picture}
        alt="photo"
        className="menu__user__picture"
        id="user-picture"
        onClick={displayMenu}
      />
    )}
  </button>
);

export const LogOutButton = () => (
  <a href="/.well-known/otoroshi/logout">
    <p>Déconnexion</p>
  </a>
);

export const PrimaryButton = ({ value, id, disabled, onClick }) => (
  <button
    onClick={onClick}
    type="submit"
    disabled={disabled}
    id={id}
    className="btn btn--blue btn--centered"
  >
    {value}
  </button>
);
